import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import {
  Provider,
  Room,
  Connected,
  NotConnected,
} from "@andyet/simplewebrtc";

// Components
import Loading from "./Loading";
import Video from "./VideoContainer";
import Chat from "./ChatContainer";

import { formatName, formatType } from '../../utils';

import PassToken from "../PassToken/";
import Header from "../../components/header";
import { isNotification, isOpenMSG, isRead } from "../../dataflow/modules/chat-module.js";
import { saveAccessToken, loadUserData } from "../../dataflow/modules/authentication-module";
import queryString from "query-string";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    tokenCall: state.auth.token,
    roomAccess: state.auth.roomAccess,
  };
};

const mapDispatchToProps = dispatch => ({
  isNotification: info => {
    dispatch(isNotification(info));
  },
  isOpenMSG: info => {
    dispatch(isOpenMSG(info));
  },
  saveAccessToken: info => {
    dispatch(saveAccessToken(info))
  },
  loadUserData: info => {
    dispatch(loadUserData(info))
  },
  isRead: info => {
    dispatch(isRead(info))
  }
});

const Blue = '#0561af';
// const Blue = 'pink';

// Style
const RoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  justify-content: ${props => (props.isChatMode) ? 'center' : 'flex-end'};
  align-items: center;
  // width: 100vw;
  height: 100vh;
  margin: 0 auto;
  background: ${Blue};
`;

class MyRoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChatMode: false,
      isChatOpened: false,
    };
  }

  toggleChat = () => {
    if (this.state.isChatMode) {
      this.setState({
        isChatMode: false,
        isChatOpened: false,
      })
    }
    if (!this.state.isChatMode) {
      this.setState({
        isChatOpened: !this.state.isChatOpened,
      });
    }

    this.props.isOpenMSG();
    this.props.isNotification(false);
    this.props.isRead(true);
  };

  async componentDidMount() {
    const { location, username, userType} = this.props;
    const { isChatOpened, isChatMode } = this.state;

    if (this.props.showChatOnly) {
      this.setState({
        isChatMode: true
      })
    }

    const type = formatType(userType)

    await this.roomAccess();
    this.setState({
      isChatOpened:
        (this.props.history.location.state &&
          this.props.history.location.state.isChatOpened) ||
        false,
    });
  }

  roomAccess = async() => {
    const { roomId } = this.props.match.params;

    let token;

    if (!this.props.tokenCall) {
      const query = queryString.parse(this.props.location.search);
      token = query.token
    }

    if (this.props.tokenCall) {
      token = this.props.tokenCall
    }

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_PASS_CODE_URL}/${roomId}/passcodes`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      
      this.props.saveAccessToken({
        doctor_passcode: response.data.doctor_passcode,
        id: response.data.id,
        roomId: this.props.match.params.roomId,
        patient_passcode: response.data.patient_passcode,
      });
    } catch (err) {
      console.log('err', err)
      console.log(err.response);
    }
  }

  render() {
    const { roomId } = this.props.match.params;
    const { location, tokenCall, tokenQuery, username, userType, history, showVideoOnly, showChatOnly} = this.props;
    const { isChatOpened, isChatMode } = this.state;

    const configUrl = `https://api.simplewebrtc.com/config/guest/2e3ea92f92b90f5ec787d37f`;
    const displayName = formatName(location, username, userType);

    if (!tokenCall && !tokenQuery) {
      return (
        <PassToken location={location} match={this.props.match} />
      );
    }

    return (
      <Provider configUrl={configUrl} displayName={displayName}>
        <RoomContainer height={window.innerHeight} isChatMode={isChatMode}>
          {!showChatOnly && !showVideoOnly && <Header displayName={displayName} username={username} userType={userType}/>}
          <NotConnected>
            <Loading/>
          </NotConnected>
          <Connected>
            <Room name={roomId}>
              {({ room, sendRtt }) => {
                if (isChatMode) {
                  return (
                    <Chat
                      displayName={displayName}
                      room={room}
                      sendRtt={sendRtt}
                      toggleChat={this.toggleChat}
                    />
                  )
                }

                return (
                  <Video
                    displayName={displayName}
                    userType={userType}
                    roomId={roomId}
                    room={room}
                    sendRtt={sendRtt}
                    history={history}
                    isChatOpened={isChatOpened}
                    toggleChat={this.toggleChat}
                  />
                );
              }}
            </Room>
          </Connected>
        </RoomContainer>
      </Provider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRoom);
